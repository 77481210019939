import React from 'react'
import { appDownload, mobile } from '../../assets/images'
import { Container } from 'react-bootstrap'
import Lottie from 'react-lottie';
import downloadButton from './../../assets/animation/4342-download-button.json'
import ScreenShotSlider from './ScreenShotSlider';

function MobileApp() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: downloadButton, // Replace with your Lottie animation JSON object
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
     
  return (
    <div class="mobile-app section" id="mobile-app">
       
        
        <Container>
        <div class="section-heading px-5">
                <h2>Mobile
                  <span> App</span>
                </h2>
                <div class="line-dec"></div>
               
              </div>
        {/* <img src={appDownload} alt="App Download" className='banner-image' /> */}
         
         <div className="row content">
           
            <div className="col-sm-7">
              <h4 >Experience Seamless Betting and Gaming with the 11exch Mobile App</h4>
              <p>Experience the best in betting and gaming with 11exch mobile app. Enjoy high-speed, top-quality casino games and immersive betting. Designed for Android, our user-friendly app offers seamless gaming and increased chances to win.</p>
           <div className='para d-flex justify-content-center align-items-center'>
          
         
          <div className='steps'>
        <h4>Simply follow these steps to install the 11exch app on your Android device :</h4>
        <ul>
<li>Go to your device's settings and locate "Security" &gt; "Unknown sources." Enable the installation of third-party software.</li>
<li>Visit the 11exch website using your mobile browser, open profile menu, and tap the "Download App" button to obtain the .apk file.</li>
<li>Once the download is complete, open the file and proceed with the installation.</li>
        </ul>
        </div>
         </div>
         <div className='d-flex justify-content-center'>
            <div className="lottie-wrapper">
              <a href="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.0.apk" target='_blank'>
                        <Lottie options={defaultOptions} />
                        </a>
                </div>
        
          </div>   
            </div>
            <div className="col-sm-5 d-flex justify-content-center align-items-center">
                <img src={mobile} alt="Mobile" className='img-fluid'/>
            </div>
           
         </div>
     
    
        <ScreenShotSlider/>
        </Container>
        </div>
        
  )
}

export default MobileApp
