import React from 'react'
import { dice, mainBg } from '../../assets/images'



function Main() {

  return (
    <div class="main-banner" id="top">
       
    <div class="container">
    {/* <img src={dice} alt=""  height={180} width={250} /> */}
      <div class="row">
        <div class="col-lg-7">

          <div class="caption header-text">
            <h6>Experience the Ultimate Online Gaming Adventure</h6>
            <div class="line-dec"></div>
            <h4>Dive <em>Into The Gaming</em> World <span>With 11exch</span></h4>
            <p>Our website provides best online gaming experience with user friendly interface and easy withdrawal and deposit facility.</p>
            <div class="main-button scroll-to-section">
              <a href="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.0.apk">Download App</a>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Main
