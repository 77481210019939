import React from 'react'
import { card, casino, contact_left, horse, services_left, slots,sliderImages1,sliderImages2,sliderImages3 } from '../../assets/images'
import {SliderComponent} from './../'

function Categories() {
  return (
    <div class="services section" id="services">
    <div class="container">
      <div class="row">
      <div class="section-heading">
                <h2>Explore The <em>Vast</em> 
                  <span> Collection</span> Of Games
                </h2>
                <div class="line-dec"></div>
                <p>Discover a vast collection of games catering to various genres and preferences. Whether you're a fan of action-packed shooters, immersive role-playing adventures, thrilling sports simulations, or mind-bending puzzle games, we have something for everyone.</p>
              </div>
    <div className="col-lg-6 slider_container">
       
<SliderComponent data={sliderImages1}/>
<SliderComponent data={sliderImages2}/>
<SliderComponent data={sliderImages3}/>


    </div>
        <div class="col-lg-6 py-5 text-light">
          <div class="row">
        
            <div class="col-lg-12">
              
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
                <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={horse} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Exchange</h4>
                </div>
                <ul>
                  <li>Cricket</li>
                  <li>Football</li>
                  <li>Tennis</li>
                  <li>Horse Racing</li>
                  <li>Greyhound Racing</li>
                </ul>
               
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
            
                <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={card} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Live Cards</h4>
                </div>
                <ul>
                  <li>Teen Patti</li>
                  <li>Poker</li>
                  <li>Andar Bahar</li>
                  <li>Dragon Tiger</li>
                  <li>Amar Akbar Anthony</li>
                </ul>
               
              
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
              <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={casino} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Live Casino</h4>
                </div>
                <ul>
                  <li>Baccarat</li>
                  <li>Roulette</li>
                  <li>Blackjack</li>
                  <li>Poker</li>
                  <a href="https://11exch.com/" target='_blank' className='text-light'>many more...</a>
                </ul>
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
              <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
              <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={slots} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Slots</h4>
                </div>
                <ul>
                  <li>Bingo</li>
                  <li>Fishing Games</li>
                  <li>Virtual Sports</li>
                  <li>Hunting</li>
                  <a href="https://11exch.com/" target='_blank' className='text-light'>many more...</a>
                </ul>
              </div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Categories
