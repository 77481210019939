import React from 'react'
import { Container } from 'react-bootstrap'

function ContactUs() {
  return (
    <div  className='offCanvas-content'>
        <Container>
        LIVE CHAT <br />
Our 24/7 Live Support service is always happy to help you.
<br /><br /><br /><br />
 

EMAIL<br /><br />

For all kind of queries, questions/offers, document verification please email us on <a href="mailto: support@11Exch.com">support@11Exch.com</a> and quote your name and account ID in subject.<br />
</Container>
    </div>
  )
}

export default ContactUs