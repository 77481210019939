import React from 'react'
import { Offcanvas } from 'react-bootstrap'

function OffCanvasComponent({value,content,show,handleClose}) {
  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom" style={{ height: '90vh' }}>
    <Offcanvas.Header closeButton>
   
            <h2>{value}</h2>
       
    </Offcanvas.Header>
    <Offcanvas.Body>
      {
      content
      }
    </Offcanvas.Body>
  </Offcanvas>
  )
}

export default OffCanvasComponent