import logo from "./11xlogo.svg";
import whitelogo from "./no-color-logo.png";
import bannerLeft from "./banner-left.png";
import bannerRight from "./banner-right.png";
import diceLoader from "./loader.gif";
import mainBg from "./mainBg.png";
import services_left from "./services-left.jpg";
import contact_left from "./contact-left.jpg";
import mobile from "././mobile.png";
import dice from "./dice.png";
// Bonus
import slider6 from "./Bonus/l10.jpg";
import slider2 from "./11percentextra.png";
import slider3 from "./Bonus/l2.jpg";
import slider4 from "./Bonus/l4.jpg";
import slider5 from "./Bonus/l8.jpg";
import slider1 from "./500percentdeposit.png";

// Games
// 1
import game1 from "./games/56768.jpg";
import game2 from "./games/56966.jpg";
import game3 from "./games/67565.jpg";
import game4 from "./games/67620.jpg";
import game5 from "./games/67640.jpg";
import game6 from "./games/67690.jpg";
import game7 from "./games/90100.jpg";
// 2
import game8 from "./games/98567.jpg";
import game9 from "./games/EURASIAN-24.jpg";
import game10 from "./games/EURASIAN-70.png";
import game11 from "./games/EVOLUTION-americanroulette-nvroruhz6teqkaop.jpg";
import game12 from "./games/SSG-Lobby.jpg";
import game13 from "./games/TVBET-lobby.jpg";
import game14 from "./games/VIVO-lobby-0.jpg";
// 3
import game15 from "./games/WZ-396.jpg";
import game16 from "./games/betgames_lobby-v1.jpg";
import game17 from "./games/ezugi-lobby-1.jpg";
import game18 from "./games/jili-92.jpg";
import game19 from "./games/livegames_lobby-v1.jpg";
import game20 from "./games/rocco-strip-club.png";
import game21 from "./games/EURASIAN-11.png";

// Svg
import casino from "./casino.svg";
import card from "./cards.svg";
import slots from "./slots.svg";
import horse from "./horse.svg";
import appDownload from "./app-download-poster1.gif";
// App Screenshots
import ss1 from "./screenshot/ss1.jpg";
import ss2 from "./screenshot/ss2.jpg";
import ss3 from "./screenshot/ss3.jpg";
import ss4 from "./screenshot/ss4.jpg";
import ss5 from "./screenshot/ss5.jpg";
import ss6 from "./screenshot/ss6.jpg";
import ss7 from "./screenshot/ss7.jpg";

const sliderImages1 = [game1 ,
game2 ,
game3 ,
game4 ,
game5 ,
game6,
game7
]
const sliderImages2 = [game8 ,
    game9 ,
    game10 ,
    game11 ,
    game12 ,
    game13,
    game14
    ]

    const sliderImages3 = [game15 ,
        game16 ,
        game17 ,
        game18 ,
        game19 ,
        game20,
        game21
        ]

const appSs=[ss1,
    ss2,
    ss3,
    ss4,
    ss5,
    ss6,
    ss7]

export {
    logo,
    bannerLeft,
    bannerRight,
    diceLoader,
    mainBg,
    services_left,
    contact_left,
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
    slider6,
    game1,
    game2,
    game3,
    game4,
    game5,
    game6,
    game7,
    game8,
    casino,
    card,
    slots,
    horse,
    appDownload,
    mobile,
    ss1,
    ss2,
    ss3,
    ss4,
    ss5,
    ss6,
    ss7,
    appSs,
    sliderImages1,
    sliderImages2,
    sliderImages3,
    dice,
    whitelogo
}
