import React, { useRef, useState } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container } from "react-bootstrap";

export default function SliderComponent({data}) {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true, // Enable auto sliding
    autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 992, // Medium screens and below
              settings: {
                slidesToShow: 1,
              },
            },
          ],
      };
  return (
    <Container>
    <Slider {...settings}>
        {
            data?.map((item,index)=><div key={index}>
      <a href="https://11exch.com/" target="_blank"><img src={item} className='img-fluid slider-image'/></a>
      </div>)
        }
    </Slider>
      
    </Container>
  );
}