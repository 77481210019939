import React from 'react'
import Slider from 'react-slick';
import { appSs } from '../../assets/images';

function ScreenShotSlider() {
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enable auto sliding
    autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 992, // Medium screens and below
              settings: {
                slidesToShow: 2,
              },
            },
          ],
      };
  return (
    <div className='slider-container'>
        <h4>Screenshot Showcase</h4>
    <Slider {...settings}>
        {
            appSs?.map((item,index)=><div key={index}>
              <a href="https://11exch.com/" target='_blank'>
       <img src={item} alt={`slide${index}`}/>
       </a>
        </div>)
        }
        
    </Slider>
    </div>
  )
}

export default ScreenShotSlider