import React from 'react'
import { slider1, slider2, slider3, slider4, slider5, slider6 } from '../../assets/images'

function Offers() {
  return (
    
    <div className='offers_and_bonuses '>
     <div className="container">
     <div class="section-heading px-5">
                <h2>Offers  &amp;
                  <span> Bonuses</span>
                </h2>
                <div class="line-dec"></div>
               
              </div>
     </div>
              {/* 1st  */}
              <div className="container">
              <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
        <div className="d-flex flex-column align-items-center py-2 offer-item">
        
        <div className='row'>
            <div className='col-sm-4 p-2'>
        <img src={slider1} alt='predictor' className='img-fluid'/>
        </div>
        <div className="col-sm-8 ">
            <div className="container">
<h4>500% Deposit Bonus</h4>
<p>+ 11% free money</p>
</div>
        </div>
        </div>
        </div>
        </a>
</div>
        {/* 2nd  */}
        <div className="container">
        <a href="https://11exch.com/" target='_blank' className='text-light text-decoration-none'>
        <div className="d-flex flex-column align-items-center py-2 offer-item">
        
        <div className='row'>
          
        <div className="col-sm-8 ">
        </div>
        <div className='col-sm-4 p-2'>
        <img src={slider2} alt='predictor' className='img-fluid'/>
        </div>
        </div>
            <div className="container">
                <h4>11% extra on Daily Deposit</h4>
                <p>No wagering. No conditions</p>
            </div>
        </div></a>
        </div>

    </div>
  )
}

export default Offers
