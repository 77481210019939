import React, { useEffect, useState } from 'react'
import { Facebook, Instagram, Telegram, Twitter } from '../../assets/icons'
import { logDOM } from '@testing-library/react'
import { logo, whitelogo } from '../../assets/images'
import { AboutUs, Blogs, Casino, ContactUs, ExclusionPolicy, FAQ, GeneralTermsAndConditions, OffCanvasComponent, Poker, PrivacyPolicy, ResponsibleGaming, SportsBookTermsAndConditions } from "./../"
import SportBettingRules from '../Sport Betting Rules'

function Footer() {
const [value, setvalue] = useState()
const [show, setShow] = useState(false);
const [offCanvasContent, setOffCanvasContent] = useState(null);

const handleClose = () => {
    setvalue('')
    setShow(false);
}
const handleShow = () => setShow(true);




    const handleOffcanvas=()=>{
if (value==='About Us') {
    handleShow()
    setOffCanvasContent(<AboutUs />);
    
}
else if (value==='Responsible Gaming') {
    handleShow()
    setOffCanvasContent(<ResponsibleGaming/>);
   
}
else if (value==='Blogs') {
    handleShow()
    setOffCanvasContent(<Blogs/>);
   
}
else if (value==='FAQ') {
    handleShow()
    setOffCanvasContent(<FAQ/>);
   
}
else if (value==='Contact Us') {
    handleShow()
    setOffCanvasContent(<ContactUs/>);
   
}
else if (value==='Sport Betting Rules') {
    handleShow()
    setOffCanvasContent(<SportBettingRules/>);
   
}
else if (value==='Casino') {
    handleShow()
    setOffCanvasContent(<Casino/>);
   
}
else if (value==='Poker') {
    handleShow()
    setOffCanvasContent(<Poker/>);
   
}
else if (value==='General Terms and Conditions') {
    handleShow()
    setOffCanvasContent(<GeneralTermsAndConditions/>);
   
}
else if (value==='Sportsbook Terms and Conditions') {
    handleShow()
    setOffCanvasContent(<SportsBookTermsAndConditions/>);
   
}
else if (value==='Self Exclusion') {
    handleShow()
    setOffCanvasContent(<ExclusionPolicy/>);
   
}
else if (value==='Privacy Policy') {
    handleShow()
    setOffCanvasContent(<PrivacyPolicy/>);
   
}
    }

    useEffect(() => {
        handleOffcanvas()
    }, [value])

    return (
        <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div style={{width: "-webkit-fill-available"}} class="col-sm-12 col-md-4 item d-flex justify-content-evenly gap-2">
                        <div >
                        <h3>About</h3>
                        <ul>
                            <li><a  onClick={e=>setvalue('About Us')}>About Us</a></li>
                            <li><a  onClick={e=>setvalue('Responsible Gaming')}>Responsible Gaming</a></li>
                            <li><a onClick={e=>setvalue('Blogs')}>Blogs</a></li>
                        </ul>
                        </div>
                        <div >
                        <h3>Help</h3>
                        <ul>
                            <li><a onClick={e=>setvalue('FAQ')}>FAQ</a></li>
                            <li><a onClick={e=>setvalue('Contact Us')}>Contact Us</a></li>
                            <li><a onClick={e=>setvalue('Sport Betting Rules')}>Sport Betting Rules</a></li>
                            <li><a onClick={e=>setvalue('Casino')}>Casino</a></li>
                            <li><a onClick={e=>setvalue('Poker')}>Poker</a></li>
                        </ul>
                        </div>
                        
                    {/*</div>*/}
                    
                    {/*    */}
                    
                    {/*<div class="col-sm-4 col-md-2 item d-flex justify-content-center">*/}
                        <div>
                        <h3>Regulations</h3>
                        <ul>
                            <li><a onClick={e=>setvalue('General Terms and Conditions')}>General Terms and Conditions</a></li>
                            <li><a onClick={e=>setvalue('Sportsbook Terms and Conditions')}>Sportsbook Terms and Conditions</a></li>
                            <li><a onClick={e=>setvalue('Self Exclusion')}>Exclusion Policy</a></li>
                            <li><a onClick={e=>setvalue('Privacy Policy')}>Privacy Policy</a></li>
                        </ul>
                        </div>
                    </div>
                </div>

                <div style={{width: "-webkit-fill-available"}} className="col-md-6 white-logo-container">
                    <a href="https://11exch.com/" target='_blank'>
                        <img src={logo} alt="Logo" className='whiteLogo'/></a>
                </div>
                <div className="col item social">
                    <a href="https://t.me/11ExchOfficial" target='_blank'><Telegram/></a>
                    <a href="https://www.instagram.com/11Exch" target='_blank'><Instagram/></a>
                    <a href="https://www.facebook.com/11Exch" target='_blank'><Facebook/></a>
                    <a href="https://twitter.com/11ExchIndia" target='_blank'><Twitter/></a>
                </div>
                <p class="copyright">11exch © 2023</p>
            </div>
        </footer>
        <OffCanvasComponent value={value} content={offCanvasContent} handleClose={handleClose} show={show}/>
    </div>

    )
}

export default Footer
